.bold {
	font-weight: 700;
}

.text- {
	&xl {
		font-size: 1.25rem;
		line-height: 1rem;
	}
	&2xl {
		font-size: 1.5rem; /* 24px */
		line-height: 2rem; /* 32px */
	}
}

.grid {
	display: grid;
}

.gap {
	gap: 16px;
	&-s {
		gap: 8px;
	}

	&-xs {
		gap: 4px;
	}
}

.rtl {
	direction: rtl;
}

.fixed {
	position: fixed;
}

.align {
	&-center {
		align-items: center;
	}
	&-start {
		align-items: flex-start;
	}
	&-end {
		align-items: flex-end;
	}
}

.justify {
	&-evenly {
		justify-content: space-evenly;
	}
	&-between {
		justify-content: space-between;
	}
	&-around {
		justify-content: space-around;
	}
	&-center {
		justify-content: center;
	}
}

.underline {
	border-bottom: 1px solid black;
}

.fit-content {
	height: fit-content;
}

.position-relative,
.relative {
	position: relative;
}

.clickable {
	cursor: pointer;
}

.flex {
	display: flex;
	&-column {
		flex-direction: column;
	}
	&-wrap {
		flex-wrap: wrap;
	}
}

.no-scroll {
	overflow: hidden;
}

.height100 {
	height: 100%;
}

.width100 {
	width: 100%;
}

.text {
	&-balance {
		text-wrap: balance;
	}

	&-align-center {
		text-align: center;
	}
}

.no-before {
	&::before {
		display: none;
	}
}

.pb {
	&-6 {
		padding-bottom: 1.5rem;
	}
}

.color {
	&-green {
		color: green;
	}
}
