@mixin bigger-font {
  font-size: 20px;
  line-height: 20px;
}

@mixin valid-color-and-background{
	color:white;
	@include valid-background();
}

@mixin valid-background {
  background: var(--valid-color);
  box-shadow: 1px 4px 14px 0 var(--valid-color, #f65944);
}

@mixin custom-scrollbar{
	scrollbar-gutter: stable;

  &::-webkit-scrollbar {
		background: transparent;
		width: var(--scrollbar-width, 8px);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--main-color);
		border-radius: 10px;
	}
}

@mixin text-over {
	position: absolute;
	top: 50%;
	font-size: 36px;
	font-weight: 900;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 14px;
	box-shadow: inset 0 0 9px 2px black;
	color: var(--valid-color);
	-webkit-text-fill-color: var(--valid-color);
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: black;
}