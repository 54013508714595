$breakpoint-mobile-l: 500px;
$breakpoint-mobile: 768px;
$breakpoint-tablet: 1100px;
$main-color: var(--main-color, #f65944);

$header-desktop-height: 80px;

$closeIcon: './assets/images/burger/close-icon.svg';
$radioIcon: './assets/images/radio-button.svg';
$radioActiveIcon: './assets/images/radio-button-active.svg';
$closePopupIcon: './assets/images/popup-close.svg';
$backTopIcon: './assets/images/back-to-top.svg';
