/* You can add global styles to this file, and also import other style files */
/* custom style for markup */

@use '@angular/material' as mat;
@use 'variables' as *;
@use 'mixins' as *;

$custom-typography: mat.define-typography-config(
	$font-family: '"Arimo",sans-serif',
);

$custom-theme: mat.define-light-theme(
	(
		typography: $custom-typography // ,color:(primary:#f65944),,,,,,,,,,,,,,,,,,
	)
);

@include mat.core($custom-typography);
@include mat.all-component-themes($custom-theme);

html,
body {
	float: left;
	width: 100%;
	height: 100%;
	overscroll-behavior: none;
	accent-color: var(--main-color);
	-webkit-font-smoothing: antialiased;
}

body {
	overflow-y: auto;
	margin: 0;
	color: #000000;
	font-size: 14px;
	font-family: 'Arimo', sans-serif !important;
	overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Arimo', sans-serif !important;
}

button,
textarea,
input,
select {
	font-family: 'Arimo', sans-serif;
	outline: none;
}

button {
	cursor: pointer;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	--main-color: #f65944;
	--main-color-alpha: #f6594470;
	--secondary-color: #fff;
	--transition-wait-time: 0.3s;
	--transition-exit-time: 0.5s;
	--button-size: 20;
	--valid-color: #38d88b;
	--invalid-color: red;
	--valid-color-rgba: rgba(56, 216, 139, 0.45);
	--scrollbar-width: 8px;
	--font-size-small: 12px;
	--font-size-mid: 14px;
	--font-size-large: 18px;
	--report-dashboard-item-height: 9rem;
	--breakpoint-mobile: 768px;
	--breakpoint-tablet: 1100px;
	--menu-button-main-color: var(--main-color);
	--menu-button-secondary-color: var(--secondary-color);

	--visit-blue: #5dacff;

	--viewtype_big-itemsize: 644px;
	--viewtype_big-image: 644px;
}

// body{
// 	font-size:var(--base-font-size, 16px) !important;
// }

.order-popup__content {
	overflow: auto;
}

.content {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
}

.row {
	width: 100%;
}

.col {
	width: 100%;
}

.image {
	width: 100%;
}

.image img {
	display: block;
	width: 100%;
	object-fit: contain;
	height: 100%;
}

a {
	text-decoration: none;
	color: #000000;
}

.hiden {
	visibility: hidden;
}

.s-gap {
	gap: 1em;
}

.flex-row {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em;
	gap: 2em;
	width: 100%;
	// position: sticky;
	// top: 0;
	// z-index: 1;
	// background: white;

	& .box {
		display: flex;
		flex-direction: column;
		font-size: 22px;
		gap: 0.2em;
		// background: white;
	}
}

.justify-align-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

/* main-wrapper style */
.main-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: $main-color;
	overflow-x: clip;
}

.main-content {
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	background-color: #ffffff;
	z-index: 1;
}

.main-content_active {
	transform: translateX(-80%);
	height: calc(100vh - 20vh);
	margin: 10vh 0;
	border-radius: 30px;
}

.main-content_active:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 11;
	cursor: pointer;
}

.main-content_no-scroll {
	overflow: hidden;
}

.main-content_absolute {
	position: absolute !important;
}

.main-menu {
	direction: rtl;
	float: right;
	width: 85%;
	height: 100%;
	padding: 30px 20px 20px;
	opacity: 0;
	transform: translateX(20vw);
	overflow-y: auto;
}

.main-menu_active {
	opacity: 1;
	transform: translateX(0);
}

.main-menu__close {
	margin-bottom: 45px;
	color: var(--secondary-color, white);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 0;
	cursor: pointer;
}

.main-menu__close:before {
	content: '';
	display: inline-block;
	width: 19px;
	height: 19px;
	margin-left: 5px;
	vertical-align: middle;
	background-image: url($closeIcon);
	background-repeat: no-repeat;
	background-size: cover;
}

.main-menu__row {
	width: 100%;
}

.main-menu__border {
	width: 100%;
	height: 1px;
	margin: 15px 0;
	background-color: rgba(255, 255, 255, 0.2);
}

.main-menu__col {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 5px;
	cursor: pointer;
}

.main-menu__col:last-child {
	margin-bottom: 0;
}

.main-menu__col-icon {
	max-width: 26px;
	margin-left: 12px;
}

.main-menu__col-title {
	color: var(--secondary-color, white);
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 0;
	padding-left: 12px;
}

.main-menu__logo {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
}

.main-menu__logo-image {
	max-width: 64px;
	max-height: 64px;
	min-width: 64px;
	min-height: 64px;
	margin-left: 15px;
	padding: 8px;
	border-radius: 17px;
	overflow: hidden;
	background: #ffffff;
	border: 1px solid #e8e8e8;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
}

.main-menu__logo-col {
}

.main-menu__logo-col-title {
	margin-bottom: 5px;
	color: var(--secondary-color, white);
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: 26px;
	letter-spacing: 0;
}

.main-menu__logo-col-descrip {
	color: var(--secondary-color, white);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0;
}

.main-menu__logo-col-descrip-bold {
	margin: 0 5px;
	font-weight: 700;
}

/* end main-wrapper style */

/* custom checkbox style */
.custom-checkbox {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.custom-checkbox__checkmark {
	position: absolute;
	top: 0;
	left: 0;
	width: 16px;
	height: 16px;
	background-image: url('./assets/images/client-profile/second-screen/check-box.svg');
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 0.3s;
}

.custom-checkbox:hover input ~ .custom-checkbox__checkmark,
.custom-checkbox input:checked ~ .custom-checkbox__checkmark {
	top: -1px;
	left: -1px;
	width: 18px;
	height: 18px;
	background-image: url('./assets/images/client-profile/second-screen/check-box-active.svg');
}

/* end of custom checkbox styles */

/* custom radio style */
.custom-radio {
	display: block;
	position: relative;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default radio */
.custom-radio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.custom-radio__checkmark {
	position: absolute;
	top: 0;
	left: 0;
	width: 16px;
	height: 16px;
	background-image: url($radioIcon);
	background-repeat: no-repeat;
	background-size: cover;
	transition: all 0.3s;
}

.custom-radio:hover input ~ .custom-radio__checkmark,
.custom-radio input:checked ~ .custom-radio__checkmark {
	top: -1px;
	left: -1px;
	width: 18px;
	height: 18px;
	background-image: url($radioActiveIcon);
}

/* end of custom radio styles */

/* custom style for popup */
.popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
}

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.6);
	z-index: 1000;
}

.popup__close {
	position: absolute;
	top: -36px;
	left: 0;
	width: 18px;
	height: 18px;
	background-image: url($closePopupIcon);
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
}

.popup__content {
	position: absolute;
	top: 50%;
	left: 50%;
	width: max(360px, min(90vw, 89%));
	padding: 20px;
	border-radius: 7px;
	background-color: #ffffff;
	transform: translate(-50%, -50%);
	z-index: 1001;
}

/* end custom style for popup */

/* start custom style for popup */
.popup__content_logout {
	padding: 45px 20px 65px;
}

.popup__content_logout .popup__content-image {
	max-width: 140px;
	margin: 0 auto 25px;
}

.popup__content_logout .popup__content-title {
	margin-bottom: 25px;
	padding: 0 20px;
	color: #212121;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0;
	text-align: center;
}

.popup__content_logout .popup__content-description {
	margin-bottom: 50px;
	color: #212121;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0;
	text-align: center;
}

.popup__content_logout .popup__content-block {
	display: flex;
	justify-content: space-between;
	width: 100%;
	max-width: 380px;
	margin: 0 auto;
}

.popup__content_logout .popup__content-button {
	width: 100%;
	max-width: 130px;
	padding: 14px 0;
	border: none;
	border-radius: 40px;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	letter-spacing: 0;
	text-align: center;
	background: #e8e8e8;
	cursor: pointer;
}

.popup__content_logout .popup__content-button_red {
	box-shadow: 1px 4px 14px 0 var(--main-color-alpha, #f65944);
	color: var(--secondary-color, white);
	background-color: $main-color;
}

/* end custom style for popup */

/* button back to top */
.back-top {
	position: fixed;
	left: 12px;
	bottom: 12px;
	width: 74px;
	height: 74px;
	background-image: url($backTopIcon);
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
}

/* end butto back to top */

/* styles for table */
.table {
	width: 100%;
	table-layout: fixed;

	tr {
		display: block;
		padding: 0 20px;
	}

	th,
	td {
		height: 36px;
		padding: 0 10px;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 12px;
		letter-spacing: 0;
		text-align: right;
	}

	th {
		height: 38px;
		font-weight: 700;
	}

	&__head {
		background-color: #e8e8e8;

		th {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				display: inline-block;
				top: 50%;
				right: -4px;
				width: 0;
				height: 0;
				margin-left: 5px;
				border-left: 5px solid transparent;
				border-right: 5px solid transparent;
				border-top: 5px solid #858585;
				transform: translateY(-50%);
			}
		}

		&-column {
			width: 100%;
		}
	}

	&__body {
		tr {
			&:nth-child(even) {
				background-color: #f8f8f8;
			}
		}

		&-column {
			width: 100%;
		}
	}
}

/* end styles for table */

/* global style for header */
.header {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	padding: 30px 20px;
	background-color: $main-color;
	z-index: 10;
}

.header_app-shop-return {
	padding: 20px;
}

.shop-filter-empty,
.shop-delivery,
.order-cart-gray,
.order-process {
	margin-top: 76px;
}

.client__wrapper,
.shop-activity,
.report-graph,
.report-table,
.drivers,
.driver-details,
.success-process,
.dashboard,
.dashboard-tables {
	padding-top: 40px;
}

.report-dashboard {
	margin-top: 50px;
}

/*.order-filters,*/
.product-detail {
	margin-top: 124px;
}

.shop__cards {
	margin-top: 158px;
}

.header {
	&__logout {
		color: #ffffff;
		font-family: Arimo;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		line-height: 14px;
		letter-spacing: 0;

		&:before {
			content: '';
			display: inline-block;
			width: 16px;
			height: 16px;
			margin-right: 5px;
			vertical-align: middle;
			background-image: url('./assets/images/logout.svg');
			background-repeat: no-repeat;
			background-size: cover;
		}
	}
}

.header__block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
}

.header__arrow {
	max-width: 18px;
	cursor: pointer;
}

.header__title {
	color: var(--secondary-color, white);
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: 16px;
	letter-spacing: 0;
	text-align: center;

	&_hello {
		direction: rtl;

		&:before {
			content: 'שלום,';
			display: inline-block;
			margin-left: 5px;
			font-weight: 400;
		}
	}
}

.header__burger {
	max-width: 24px;
	cursor: pointer;
}

.header_white {
	background-color: #ffffff;
}

.header_white .header__title {
	color: #000000;
}

.header__search {
	position: relative;
	width: 100%;
	max-width: 206px;
}

.header__search_small {
	position: relative;
	width: 100%;
	max-width: 185px;
}

.header__search-input {
	width: 100%;
	height: 36px;
	padding-inline: 35px;
	border: none;
	border-radius: 5px;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 12px;
	letter-spacing: 0;
	text-align: right;
}

.header__search-input::placeholder {
	color: #c7c7c7;
}

.header__clear-icon {
	position: absolute;
	top: 50%;
	right: 6px;
	width: 25px;
	height: 25px;
	padding: 0;
	border: none;
	background-image: url('./assets/images/header/clear-icon.svg');
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: cover;
	transform: translateY(-50%);
	cursor: pointer;
}

.header__barcode-icon {
	border-radius: 28%;
	right: unset;
	left: 6px;
	background-size: auto;
	background-repeat: no-repeat;
	background-position: center;
	background-image: url('./assets/images/header/barcode-icon.svg');
}

.header__search-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	width: 25px;
	height: 25px;
	padding: 0;
	border: none;
	background-color: transparent;
	transform: translateY(-50%);
	cursor: pointer;
}

@-webkit-keyframes move_search {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@-moz-keyframes move_search {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@-o-keyframes move_search {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes move_search {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.header__search-icon_active img {
	-webkit-animation: 1s linear 0s infinite normal move_search;
	-moz-animation: 1s linear 0s infinite normal move_search;
	-o-animation: 1s linear 0s infinite normal move_search;
	animation: 1s linear 0s infinite normal move_search;
}

.header__box {
	position: relative;
	display: flex;
}

.header__basket {
	position: relative;
	margin-right: 15px;
	cursor: pointer;

	& .expand-click-area {
		position: absolute;
		right: -1rem;
		top: 0;
		width: 2rem;
		height: 100%;
	}
}

.header__basket-image {
	position: relative;
	width: 21px;
	height: 19px;
	background-image: url('./assets/images/header/basket-icon.svg');
	background-repeat: no-repeat;
	background-size: cover;

	&.bigger {
		width: 30px;
		height: 28px;
	}
}

.header__basket-image-number {
	position: absolute;
	top: -6px;
	right: -6px;
	width: 17px;
	height: 17px;
	border-radius: 50px;
	color: #ffffff;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 17px;
	letter-spacing: 0;
	text-align: center;
	background: #000000;
}

.header__basket-text {
	position: absolute;
	left: 0;
	bottom: -17px;
	padding-top: 5px;
	color: var(--secondary-color);
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 12px;
	letter-spacing: 0;

	&-VAT {
		bottom: -19px;
		left: 0;
		// direction: ltr;
	}

	&.bigger {
		width: 100%;
		text-align: center;
		font-size: 12px;
	}
}

.header__barcode {
	width: 21px;
	height: 19px;
	background-image: url('./assets/images/header/barcode-icon.svg');
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
}

/* end global style for header */

/* start filters style */
.filter-sort__box {
	display: flex;
}

.filter-sort__box .filter-sort__row {
	margin-left: 25px;
}

.filter-sort__row {
	display: flex;
	align-items: center;
	padding: 10px 15px;
	border-radius: 40px;
	background: #e8e8e8;
	cursor: pointer;
	max-height: 36px;
}

.filter-sort__row_active {
	background-color: $main-color;
}

.filter-sort__row_active .filter-sort__row-name {
	color: var(--secondary-color, white);
}

.filter-sort__row_active .filter-sort__row-img {
	filter: invert(1);
}

.filter-sort__row-img {
	max-width: 12px;
	margin-left: 5px;
}

.filter-sort__row-img_layer {
	max-width: 13px;
}

.filter-sort__row-name {
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 14px;
	letter-spacing: 0;
	text-align: center;
	text-wrap: balance;
}

.filter-sort__row-block {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.filter-sort__row_double {
	border: 1px solid #e8e8e8;
	background-color: transparent;
	cursor: default;
}

.filter-sort__row_double .filter-sort__row-block {
	position: relative;
	margin-right: 10px;
	/*border-right: 1px solid #E8E8E8;*/
	cursor: pointer;
}

.filter-sort__row_double .filter-sort__row-block_active {
	opacity: 0.6;
}

.filter-sort__row_double .filter-sort__row-block:first-child:before {
	content: '';
	position: absolute;
	top: 0;
	right: -10px;
	width: 1px;
	height: 100%;
	background-color: #e8e8e8;
	cursor: default;
}

.filter-sort__row_double .filter-sort__row-block:last-child {
	margin-right: 0;
	margin-left: 10px;
	border: none;
}

.filter-sort__row_view {
	direction: rtl;
	padding: 0;
	background-color: transparent;
	min-width: 40px;
	justify-content: space-evenly;
}

.filter-sort__row_mobile {
	display: block;
}

.filter-sort__row-view-type {
	width: 14px;
	height: 14px;
	margin-left: 14px;
	background-repeat: no-repeat;
	background-size: cover;
}

.filter-sort__row-view-type:last-child {
	margin: 0;
}

.filter-sort__row-view-type_big {
	background-image: url('./assets/images/filters/view-type-big.svg');
}

.filter-sort__row-view-type_small {
	background-image: url('./assets/images/filters/view-type-small.svg');
}

.filter-sort__row-view-type_medium {
	background-image: url('./assets/images/filters/view-type-medium.svg');
}

.filter-sort__row-view-type_inline {
	background-image: url('./assets/images/filters/view-type-inline.svg');
}

.filter-sort__row-view-type_active.filter-sort__row-view-type_big {
	background-image: url('./assets/images/filters/view-type-big-active.svg');
}

.filter-sort__row-view-type_active.filter-sort__row-view-type_small {
	background-image: url('./assets/images/filters/view-type-small-active.svg');
}

.filter-sort__row-view-type_active.filter-sort__row-view-type_medium {
	background-image: url('./assets/images/filters/view-type-medium-active.svg');
}

.filter-sort__row-view-type_active.filter-sort__row-view-type_inline {
	background-image: url('./assets/images/filters/view-type-inline-active.svg');
}

/* end filters style */

/* general toogle styles */
.toogle {
	display: flex;
	flex-direction: row-reverse;
	cursor: pointer;
}

.toogle__title {
	margin: 0 7px;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	letter-spacing: 0;
}

.toogle__input {
	display: none;
}

.toogle__circle {
	position: relative;
	width: 40px;
	height: 20px;
	border-radius: 50px;
	background-color: #dcdcdc;
	transition: all 0.3s;
}

.toogle__circle:before {
	content: '';
	position: absolute;
	top: 50%;
	right: calc(0% + 2px);
	width: 16px;
	height: 16px;
	border-radius: 50px;
	background-color: #fff;
	transform: translateY(-50%);
	transition: all 0.3s;
}

.toogle__input:checked ~ .toogle__circle {
	background-color: $main-color;
}

.toogle__input:checked ~ .toogle__circle:before {
	right: calc(100% - 18px);
}

/* end general toogle styles */

/* new response style for tablet */

/* end new response style for tablet */

/* styles for tablet */
@media (min-width: $breakpoint-mobile) {
	.header {
		padding: 30px 40px;
	}

	.header__search {
		max-width: 373px;
	}

	.header__search-input {
		height: 44px;
		font-size: 16px;
	}

	.header__burger {
		max-width: 33px;
	}

	.header__arrow {
		max-width: 27px;
	}

	.header__logout {
		font-size: 18px;
		line-height: 18px;
	}

	.header__logout:before {
		width: 20px;
		height: 20px;
	}

	.header__title {
		font-size: 26px;
	}

	.header__barcode {
		width: 24px;
		height: 22px;
	}

	.header__basket {
		margin-right: 30px;
	}

	.header__basket-image {
		width: 24px;
		height: 22px;
	}

	.header__basket-image-number {
		top: -7px;
		right: -7px;
		width: 20px;
		height: 20px;
		font-size: 12px;
		line-height: 20px;
	}

	.header__basket-text {
		font-size: 12px;
	}

	.custom-checkbox__checkmark {
		width: 22px;
		height: 22px;
	}

	.custom-checkbox:hover input ~ .custom-checkbox__checkmark,
	.custom-checkbox input:checked ~ .custom-checkbox__checkmark {
		width: 24px;
		height: 24px;
	}

	.client__wrapper,
	.shop-activity,
	.report-graph,
	.report-table,
	.drivers,
	.driver-details,
	.dashboard,
	.dashboard-tables {
		padding-top: 86px;
	}

	.shop__cards {
		margin-top: 210px;
	}

	.filter-sort__row-img {
		max-width: 15px;
	}

	.filter-sort__row-name {
		font-size: 16px;
		line-height: 16px;
	}

	.filter-sort__row-view-type {
		width: 23px;
		height: 23px;
	}

	.back-top {
		width: 80px;
		height: 80px;
	}

	.shop-filter-empty,
	.shop-delivery,
	.order-cart-gray,
	.order-process {
		margin-top: 84px;
	}

	.product-detail {
		margin-top: 148px;
	}

	.main-menu__close:before {
		width: 22px;
		height: 22px;
	}

	.main-menu__row.main-menu__logo {
		padding-top: 20px;
		margin-bottom: 50px;
	}

	.main-menu__logo-image {
		max-width: 122px;
		min-width: 122px;
		max-height: 122px;
		min-height: 122px;
	}

	.main-menu__logo-col-title {
		margin-bottom: 15px;
		font-size: 32px;
		line-height: 32px;
	}

	.main-menu__logo-col-descrip {
		font-size: 18px;
		line-height: 18px;
	}

	.main-menu__col {
		margin-bottom: 18px;
	}

	.main-menu__col-icon {
		max-width: 38px;
	}

	.main-menu__col-title {
		font-size: 20px;
	}

	// styles for table
	.table {
		&__head {
			th {
				height: 64px;
				font-size: 20px;
			}
		}

		&__body {
			td {
				font-size: 16px;
			}
		}
	}

	// end styles for table

	// styles for bread crumbs
	.bread-crumbs {
		display: flex;
		align-items: center;
		width: 100%;
		margin: 0;
		padding: 0;

		&__item {
			list-style: none;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: 18px;
			letter-spacing: 0;

			&:after {
				content: '';
				display: inline-block;
				width: 7px;
				height: 13px;
				margin: 0 15px;
				color: #c7c7c7;
				background-image: url('./assets/images/bread-crumbs-arrow.svg');
				background-repeat: no-repeat;
				background-size: cover;
			}

			&:last-child {
				font-weight: 700;

				&:after {
					display: none;
				}
			}
		}
	}

	// end styles for bread crumbs

	/* toogle responsive styles */
	.toogle__circle {
		width: 50px;
		height: 25px;
	}

	.toogle__circle:before {
		width: 20px;
		height: 20px;
		right: calc(0% + 3px);
	}

	.toogle__input:checked ~ .toogle__circle:before {
		right: calc(100% - 23px);
	}

	.toogle__title {
		font-size: 16px;
		line-height: 25px;
	}

	/* end toogle responsive styles */

	/* logout popup */
	.popup__content_logout .popup__content-image {
		max-width: 200px;
	}

	.popup__content_logout .popup__content-title {
		font-size: 22px;
	}

	.popup__content_logout .popup__content-description {
		font-size: 18px;
	}

	.popup__content_logout .popup__content-button {
		max-width: 140px;
		font-size: 18px;
	}

	/* end logout popup */
}

/* end styles for tablet */

.sortable-clickable {
	cursor: pointer;
	transition: all 0.2s;
}

.sortable-clickable:hover,
.sortable-clickable.active {
	box-shadow: 0 0 7px 4px black;
}

.sortable-clickable:active {
	box-shadow: 0 0 4px 2px black;
}

.hamburger-button {
	background: transparent;
	border: unset;
	border-radius: 1rem;
	position: fixed;
	top: 2.5vh;
	right: 2vw;
	display: flex;
	z-index: 2;

	&:hover {
		scale: 1.1;
	}

	&.position-initial {
		position: initial;
	}

	& .line {
		transform-origin: center;
	}

	&[aria-expanded='true'] {
		& .line {
			fill: var(--secondary-color);
		}

		& :is(.top, .bottom) {
			y: 45;
		}

		& .top {
			rotate: 45deg;
		}

		& .middle {
			opacity: 0;
		}

		& .bottom {
			rotate: -45deg;
		}
	}
}

.empty-line {
	margin-bottom: 45px;
	display: block;
}

.valid {
	&-color {
		color: var(--valid-color);
	}

	&-background {
		background: var(--valid-color);
	}
}

.invalid {
	&-color {
		color: var(--invalid-color);
	}

	&-background {
		background: var(--invalid-color);
	}
}

.main {
	&-color {
		color: $main-color;
	}

	&-background {
		background: $main-color;
	}
}

.secondary {
	&-color {
		color: var(--secondary-color);
	}

	&-background {
		background: var(--secondary-color);
	}
}

.warning {
	&-color {
		color: #ffa452;
	}

	&-background {
		background: #ffa452;
	}
}

.drop-shadow {
	filter: drop-shadow(2px 4px 6px black);
}

snack-bar-container.snack-bar {
	background: $main-color;
	color: var(--secondary-color, white);

	& button {
		color: var(--secondary-color, white);
	}
}

.report-dashboard {
	& .container {
		background: $main-color;

		@include custom-scrollbar;
	}
}

.custom-scrollbar {
	@include custom-scrollbar;
}

.padding-1 {
	padding: 1rem;
}

.arrow {
	position: absolute;
	height: 10px;
	width: 10px;
	right: -5px;
	top: 50%;
	transform: translateY(-50%);
}

.override-bidi .mat-option-text {
	unicode-bidi: isolate-override;
}

.report-dashboard__item {
	height: var(--report-dashboard-item-height);
}

#dropdown[dir='rtl'] {
	& .dropdown-btn {
		text-align: right;
		& .selected-item-container {
			float: right;
		}
	}

	& .dropdown-multiselect__caret {
		right: unset;
		left: 1px;
	}

	& input + div {
		padding-left: initial;
		padding-right: 2em;
		text-align: right;

		&::before {
			left: unset;
			right: 0;
		}

		&::after {
			left: unset;
			right: 4px;
		}
	}
}

.whatsapp-icon {
	position: fixed;
	bottom: 1em;
	left: 1em;
	width: 65px;
	z-index: 1;
	transition: all 0.2s;

	&-moved {
		bottom: 6em;
	}
}

.back-arrow {
	&-text {
		font-size: 18px;
		color: var(--secondary-color);
	}
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary,
.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
.mat-checkbox-checked.mat-primary .mat-checkbox-background {
	background: $main-color !important;
}

.leaflet-touch .leaflet-control-attribution {
	display: none;
}

.leaflet-control-zoom.leaflet-bar.leaflet-control {
	z-index: 5;
}

@media (max-width: 359px) {
	.filter-sort__row {
		padding: 10px;
	}

	.filter-sort__row_view {
		padding: 0;
	}
}

@media (min-width: 400px) {
	:root {
		--report-dashboard-item-height: 8rem;
	}
}

@media (max-width: $breakpoint-mobile) {
	.flex-row .box {
		font-size: 14px;
	}
}

@media (min-width: $breakpoint-mobile) {
	:root {
		--report-dashboard-item-height: 10rem;
	}
	.hamburger-button {
		top: 1.2vh;
		top: 1.2dvh;
		right: 1.2vw;
		right: 1.2dvw;
	}
}

@media (min-width: $breakpoint-tablet) {
	:root {
		--report-dashboard-item-height: 9rem;
	}
	.hamburger-button {
		zoom: 1.4;
	}
}

@media ($breakpoint-mobile <= width <= $breakpoint-tablet) {
	.shop__card-marker,
	.shop__card-block,
	.filter-sort__row,
	.order-gray__row,
	.header__block,
	.order-product__item,
	.order-popup__header-search,
	.order-popup__header-title,
	.order-popup__row,
	.order-sort__row,
	.order-popup__button,
	.auth__avatar,
	.auth__form-controls,
	.auth__actions,
	.call-control,
	.auth__form-close,
	.auth__form-title,
	.auth__help *,
	.header-home *,
	.content__header *,
	.greeting,
	.question,
	.client-info__title,
	.client-info__number,
	.client-info__block,
	.client-info__certificate,
	.client-profile__button,
	.client-profile__block *,
	.client-profile__rejected-table *,
	.client-reports__date,
	.client-reports__name,
	.client-reports__comment,
	.popup__content *,
	.shop-activity__products,
	.shop-activity__product-summ,
	.client-order__item-row *,
	.client-order__item-status,
	.client-details__item-row *,
	.client-details__item-status,
	.client__product-title,
	.client__product-block-row,
	.client-details__block-button,
	.report-dashboard__item *,
	.product-detail__back,
	.product-detail__item-corner,
	.product-detail__item-title,
	.product-detail__item-info-title,
	.product-detail__item-info-number,
	.product-detail__item-count-col,
	.product-detail__item-price-block,
	.product-detail__item-more-title,
	.product-detail__item-more-block-row,
	.product-detail__item-button,
	.order-cart-gray__row,
	.order-step__item,
	.order-step__product-row,
	.order-step__product-close,
	.order-step__product-title,
	.order-step__product-block-row,
	.order-step__block-row,
	.order-step__block-button,
	.order-process__row,
	.report-table__type,
	.report-table__table,
	.report-table__col,
	.report-table__button,
	.report-table__search-result,
	.report-table-popup,
	.report-graph__type,
	.report-graph__vertical,
	.graph-horizontal__legend-value,
	.graph-horizontal__legend-param,
	.product-detail__button,
	.product-detail__popup-row,
	.drivers__title,
	.drivers__travel,
	.drivers__item-title,
	.drivers__item-code,
	.drivers__item-number,
	.drivers__item-address-text,
	.driver-details__gray-title,
	.driver-details__gray-block,
	.driver-details__table th,
	.driver-details__table td,
	.driver-details__summary-col,
	.driver-details__notes-title,
	.driver-details__notes-button,
	.success-process__row .row,
	.dashboard .dashboard__row,
	.dashboard-tables .dashboard-tables__row {
		zoom: 1.2;
	}

	.popup__content.normal-zoom {
		zoom: 1;
		& * {
			zoom: 1;
		}
	}

	.hamburger-button {
		zoom: 1.5;
	}

	.report-table-popup *,
	.shop-range__block *,
	.product-detail__popup *,
	.product-popup *,
	.product-detail-popup * {
		zoom: 1;
	}

	.product-popup {
		.order-product__item-title,
		.order-product__item-info,
		.order-product__item-price-title,
		.order-product__item-price-number,
		.order-product__item-info-count {
			zoom: 1.2;
		}
	}

	.product-detail-popup {
		.product-detail__item-corner,
		.product-detail__item-title,
		.product-detail__item-info-title,
		.product-detail__item-info-number,
		.product-detail__item-count-col,
		.product-detail__item-price-block,
		.product-detail__item-more-title,
		.product-detail__item-more-block-row,
		.product-detail__item-button {
			zoom: 1.2;
		}
	}

	.client__wrapper {
		padding-top: 225px;
	}

	.header__clear-icon {
		width: 25px;
		height: 25px;
	}

	section.order-cart-gray {
		margin-top: 93px;
	}

	.success-process {
		padding-top: 93px;
	}

	.graph-horizontal__legend-param .graph-horizontal__legend-param-item .graph-horizontal__legend-param-item-line-wrapper {
		width: calc(100% - 69px);
	}
}

.cdk-describedby-message-container {
	direction: rtl;
}

// map-distribution marker change color
@for $i from 0 to 12 {
	.filter-hue-#{$i * 30} {
		filter: hue-rotate(#{$i * 30}deg);
	}
}

// animations

@media (prefers-reduced-motion: no-preference) {
	.main-content {
		transition-duration: 1s;
		// transition-timing-function: ease;
		transition-delay: 0s;
		transition-property: transform, width, height, background, margin;
	}

	.main-menu {
		transition: all 1.1s ease;
	}

	.main-menu_active {
		transition: all 0.3s ease-out;
	}

	.filter-sort__row {
		transition: all 0.3s;
	}

	.filter-sort__row-view-type {
		transition: all 0.3s;
	}

	.hamburger-button {
		transition: all 0.15s ease-in-out;

		& .line {
			transition: y var(--transition-exit-time) ease-in var(--transition-exit-time), rotate var(--transition-exit-time) ease-in, opacity 0ms var(--transition-exit-time),
				fill var(--transition-exit-time);
		}

		&[aria-expanded='true'] {
			& .line {
				transition: y var(--transition-wait-time) ease-in, rotate var(--transition-wait-time) ease-in var(--transition-wait-time), opacity 0ms var(--transition-wait-time),
					fill 0ms var(--transition-wait-time);
			}
		}
	}
}

@media (max-width: $breakpoint-mobile) and (preferes-reduce-motion: no-preference) {
	.main-content {
		transition-duration: 0.75s;
		transition-timing-function: cubic-bezier(0.26, 0.71, 0.76, 0.94);
	}
}

.mat-icon-button {
	font: 'Material Icons' !important;
}

.force-valid-color {
	color: var(--valid-color) !important;
}

.mat-button-toggle-appearance-standard {
	background: var(--main-color);
	color: var(--secondary-color);
}

mat-form-field.bold-placeholder input {
	font-weight: 700 !important;
}

.fixed-table-headers {
	position: sticky;
	top: 0;
	background: white;
}

.remark-input {
	border: 4px solid black !important;
}

.marker-pdf-button {
	width: 100%;
	border-radius: 40px;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 32px;
	background-color: #e8e8e8;
	cursor: pointer;
}

.timepicker__actions {
	gap: 10px;
}

.mat-tab-label.flex-1 {
	flex: 1;
	&.text-2xl {
		font-size: 1.5rem; /* 24px */
		line-height: 2rem; /* 32px */
	}
}
