.bold {
  font-weight: 700;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1rem;
}
.text-2xl {
  font-size: 1.5rem; /* 24px */
  line-height: 2rem; /* 32px */
}

.grid {
  display: grid;
}

.gap {
  gap: 16px;
}
.gap-s {
  gap: 8px;
}
.gap-xs {
  gap: 4px;
}

.rtl {
  direction: rtl;
}

.fixed {
  position: fixed;
}

.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.align-end {
  align-items: flex-end;
}

.justify-evenly {
  justify-content: space-evenly;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-center {
  justify-content: center;
}

.underline {
  border-bottom: 1px solid black;
}

.fit-content {
  height: fit-content;
}

.position-relative,
.relative {
  position: relative;
}

.clickable {
  cursor: pointer;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.no-scroll {
  overflow: hidden;
}

.height100 {
  height: 100%;
}

.width100 {
  width: 100%;
}

.text-balance {
  text-wrap: balance;
}
.text-align-center {
  text-align: center;
}

.no-before::before {
  display: none;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.color-green {
  color: green;
}